import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
// Style Imports
import Layout from "../../components/Layout/Layout";
import AboutUsBanner from "../../components/AboutUsBanner/about-us-banner";

export default function About({ data }) {
  const [error, setError] = useState(null);
  useEffect(() => {
    try {
      if (!data) {
        throw new Error("Data not available");
      }
    } catch (error) {
      setError(error);
    }
  }, [data]);

  if (error) {
    return <span>{error.map((err) => err.message).join(",")}</span>;
  }

  return (
    <Layout
      pageTitle={"About"}
      navData={
        data.allContentfulWebPages.edges[0].node.pages[1].pageLayout.navigation
      }
      footerData={
        data.allContentfulWebPages.edges[0].node.pages[0].pageLayout.footer
      }
    >
      <AboutUsBanner
        aboutUsBannerData={
          data.allContentfulWebPages.edges[0].node.pages[1].pageLayout
            .layoutMain[0]
        }
        socialMediaAbout={
          data.allContentfulWebPages.edges[0].node.pages[1].pageLayout
            .layoutMain[1].buttonsContainer
        }
        contactUsAbout={
          data.allContentfulWebPages.edges[0].node.pages[0].pageLayout
            .layoutMain[2]
        }
      ></AboutUsBanner>
    </Layout>
  );
}

export const aboutPageData = graphql`
query AboutPageQuery {
  allContentfulWebPages {
    edges {
      node {
        pages {
          pageLayout {
            navigation {
              navigationPages {
                pageTitle
                pageSlug
                pageLayout {
                  layoutMain {
                    ... on ContentfulContainer {
                      containerItems {
                        ... on ContentfulCategoryTeaser {
                          category {
                            categoryInnerTitle
                            categorySlug
                            categoryProducts {
                              product {
                                productInnerTitle
                                productSlug
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              navigationImages {
                file {
                  url
                }
                description
              }
              navigationMediaIcons {
                socialMediaIcon {
                  file {
                    url
                  }
                  description
                }
                socialMediaTitle
                socialMediaLink
              }
            }
            layoutMain {
              ... on ContentfulAboutUsBanner {
                id
                aboutUsBannerTitle
                aboutTextRed
                aboutUsImages {
                  file {
                    url
                  }
                  title
                }
                description1 {
                  description1
                }
                description2 {
                  description2
                }
                secondTitle
                titleBanner3
                logoBanner1 {
                  file {
                    url
                  }
                }
                logoBanner2 {
                  file {
                    url
                  }
                }
                logoBanner3 {
                  file {
                    url
                  }
                }
                description3 {
                  description3
                }
                description4 {
                  description4
                }
                description5
                description6 {
                  description6
                }
                description7 {
                  description7
                }
                description8 {
                  description8
                }
                description9 {
                  description9
                }
                shortTextBaner
                aboutUsMobileBanner {
                  file {
                    url
                  }
                }
                processTitle
                processSecondTitle
                processRedText
                processImage {
                  file {
                    url
                  }
                }
                processDescription1 {
                  processDescription1
                }
                processDescription2 {
                  processDescription2
                }
                processDescription3 {
                  processDescription3
                }
                processDescription4 {
                  processDescription4
                }
                processDescription5 {
                  processDescription5
                }
                processDescription6 {
                  processDescription6
                }
                processDescription7 {
                  processDescription7
                }
                processDescription8 {
                  processDescription8
                }
              }
              ... on ContentfulButtonsContainer {
                buttonsContainer {
                  socialMediaLink
                  socialMediaTitle
                }
              }
              ... on ContentfulContactUsComponent {
                contactUsDescription
                contactUsLargeTitle
                button {
                  buttonText
                  buttonLink {
                    ... on ContentfulPage {
                      pageSlug
                    }
                  }
                }
                contactUsImage {
                  file {
                    url
                  }
                }
              }
            }
            footer {
              subscribeText
              subscribeDescription
              footerButtonText
              blockTitleFollowUs
              socialMediaLogosFooter {
                socialMediaIcon {
                  file {
                    url
                  }
                }
                socialMediaLink
              }
              followUsDescription
              blockTitleAddress
              blockInfoAddress
              blockTitleCallUs
              blockInfoCallUs
              blockTitleWriteUs
              blockInfoWriteUs
              blockTitleExplore
              footerPages {
                pageTitle
                pageSlug
              }
              copyrightText
              googleMapsAddress {
                googleMapsAddress
              }
            }
          }
        }
      }
    }
  }
}

`;
